<template>
  <div>
    <div v-if="lockoutPeriod">
      <v-row>
        <v-col>
          <v-card>
            <h3>{{ lockoutMessage }}</h3>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="mb-4"> Create Invoice </v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-menu
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      eager
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Invoice Month"
                          v-model="selectedMonth"
                          class="header-inputs flex-grow-0"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="selectedMonth" type="month"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="selectedUnit"
                      :items="units"
                      label="Unit"
                      item-text="name"
                      required
                      hide-details="auto"
                      class="mb-6"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col md="3">
                    <v-menu
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      eager
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Invoice Date"
                          v-model="invoiceDate"
                          class="header-inputs flex-grow-0"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                          @blur="checkForLockoutPeriod()"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="invoiceDate"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <!-- <v-form ref="form"> -->
                    <v-text-field
                      v-model="invoiceNumber"
                      label="Enter unique invoice number"
                      outlined
                      dense
                      :rules="[rules.required, rules.emailMatch, rules.alphaNumeric]"
                      @blur="checkForLockoutPeriod()"
                    ></v-text-field>
                    <!-- </v-form> -->
                  </v-col>
                  <CBSTotalsv2
                    v-if="ticketList !== null"
                    :ticketList="ticketList"
                    @updateTotals="updateTotals"
                    :style="`${totals !== null && !isLoading ? '' : 'display:none'}`"
                  />
                </v-row>

                <v-row v-if="isLoading" class="d-flex justify-center">
                  <div class="d-flex justify-center">
                    <half-circle-spinner :animation-duration="1000" :size="60" :color="'white'" />
                  </div>
                </v-row>
                <div v-if="totals !== null && !isLoading">
                  <v-row>
                    <v-col>
                      <div v-if="!invoiceSubmitted">
                        <v-row>
                          <v-col>
                            <v-switch
                              v-model="isReportVisible"
                              inset
                              class="mt-0"
                              :label="`Show report data`"
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-switch
                              v-if="isReportVisible"
                              v-model="showApprovedTickets"
                              inset
                              class="mt-0"
                              :label="`Show approved tickets`"
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-alert v-if="unApprovedTicketCount > 0" color="primary" dark density="compact">
                              <p :style="{ 'font-size': '12px' }">
                                There are unapproved tickets in the current date selection. All tickets must be approved
                                before an invoice can be submitted.
                              </p>
                            </v-alert>
                            <v-alert v-if="uninvoicedTickets.length" color="primary" dark density="compact">
                              <p :style="{ 'font-size': '12px' }">
                                There are uninvoiced tickets from previous months. All tickets from previous months must
                                be submitted before an invoice for this month can be submitted.
                              </p>
                            </v-alert>
                            <v-checkbox
                              v-else
                              v-model="agreement"
                              label="I agree to the totals"
                              class="shrink mr-4 mt-0"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn color="primary" class="mr-3" @click="viewPDF" :disabled="isSubmitDisabled()">
                              View PDF <v-icon v-if="!submitLoading" class="ml-2">{{ icons.mdiFileEyeOutline }}</v-icon>
                            </v-btn> </v-col
                          ><v-col>
                            <v-btn color="success" @click="submitInvoice" :disabled="isSubmitDisabled()">
                              Submit <v-icon v-if="!submitLoading" class="ml-2">{{ icons.mdiUpload }}</v-icon>
                              <half-circle-spinner
                                class="ml-2"
                                v-else
                                :animation-duration="1000"
                                :size="20"
                                :color="'white'"
                              />
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="mt-3" v-else>
                        <h3>
                          Invoice #
                          <a @click="viewPDF">{{ invoiceNumber }}</a>
                          has been successfully submitted
                        </h3>
                      </div>
                    </v-col>
                    <v-col v-if="!invoiceSubmitted" class="ml-16 mr-16" cols="6">
                      <v-progress-linear
                        v-if="submitLoading"
                        :value="loadingValue"
                        stream
                        buffer-value="0"
                        color="primary"
                      ></v-progress-linear>
                      <h3 v-if="submitLoading" class="d-flex justify-center mt-3">{{ loadingMessage }}</h3>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-alert type="error">Hello</v-alert> -->
      <!-- <button @click="sendEmail()">Send Email</button> -->

      <CBSDatav2
        :ticketList="ticketList"
        :isReportVisible="isReportVisible"
        @updateApprovedTicketCount="updateApprovedTicketCount"
        :showApprovedTickets="showApprovedTickets"
      ></CBSDatav2>
    </div>
  </div>
</template>

<script>
import { mdiFileEyeOutline, mdiUpload } from '@mdi/js'
import { HalfCircleSpinner } from 'epic-spinners'
import firebase from 'firebase'
import moment from 'moment'
const axios = require('axios').default

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import CBSDatav2 from '../components/CBSDatav2.vue'
import CBSTotalsv2 from '../components/CBSTotalsv2.vue'
// import { generateTicketList } from '../components/GenerateTicketLlist.js'
import { generateTicketList } from '../components/GenerateTicketListV2.js'
import { generateDocDefinition } from '../components/invoicePDF.js'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  data() {
    return {
      docDefinition: null,
      orgData: null,
      agreement: false,
      selectedMonth: null,
      invoiceDate: null,
      invoiceNumber: null,
      selectedUnit: null,
      units: [],
      usedInvoiceNumbers: [],
      ticketListWithInvoiced: null,
      isLoading: false,
      submitLoading: false,
      invoiceSubmitted: false,
      totals: null,
      revenue: null,
      deductions: null,
      afterCommission: null,
      isReportVisible: false,
      base64Logo: null,
      loadingValue: 0,
      loadingMessage: '',
      lockoutPeriod: false,
      lockoutMessage: '',
      unApprovedTicketCount: 0,
      uninvoicedTickets: [],
      // ticketsOnUnapprovedJobsCount: 0,
      rules: {
        required: value => !!value || 'Required.',
        alphaNumeric: value => /^[a-zA-Z0-9]*$/.test(value) || 'Only letters and numbers are allowed.',
        emailMatch: () =>
          !this.usedInvoiceNumbers.includes(this.invoiceNumber) || `That invoice number has already been used`,
      },
      icons: {
        mdiUpload,
        mdiFileEyeOutline,
      },
      showApprovedTickets: true,
    }
  },
  components: {
    CBSTotalsv2,
    HalfCircleSpinner,
    CBSDatav2,
  },
  methods: {
    sendEmail() {
      const promise = new Promise((resolve, reject) => {
        let docDefinition = generateDocDefinition(
          this.revenue,
          this.deductions,
          this.afterCommission,
          this.totals,
          this.ticketList,
          this.selectedMonth,
          this.invoiceDate,
          this.invoiceNumber,
          this.selectedUnit,
          this.orgData,
          this.base64Logo,
        )

        const pdfDocGenerator = pdfMake.createPdf(docDefinition)
        pdfDocGenerator.getBlob(async blob => {
          var formData = new FormData()
          formData.append('file', blob)
          let data = {
            orgData: this.orgData,
            userData: JSON.parse(sessionStorage.userData),
            selectedMonth: this.selectedMonth,
            selectedUnit: this.selectedUnit,
            invoiceDate: this.invoiceDate,
            invoiceNumber: this.invoiceNumber,
          }
          try {
            let response = await axios.post(
              // 'http://127.0.0.1:5001/jdcservices-69fd4/us-central1/invoiceCreatedEmail',
              'https://us-central1-jdcservices-69fd4.cloudfunctions.net/invoiceCreatedEmail',
              formData,
              {
                params: data,
              },
            )
            if (response.status === 200) {
              resolve(true)
            } else {
              this.showError('Error sending invoice email.')
              resolve(false)
            }
          } catch (error) {
            this.showError('Error sending create invoice network request.')
            resolve(false)
          }
        })
      })
      return promise
    },
    viewPDF() {
      const lockoutPeriodActive = this.checkForLockoutPeriod()
      if (!lockoutPeriodActive) {
        let docDefinition = generateDocDefinition(
          this.revenue,
          this.deductions,
          this.afterCommission,
          this.totals,
          this.ticketList,
          this.selectedMonth,
          this.invoiceDate,
          this.invoiceNumber,
          this.selectedUnit,
          this.orgData,
          this.base64Logo,
        )
        pdfMake.createPdf(docDefinition).download(`${this.invoiceNumber}.pdf`)
      }
    },
    updateDocDefinition(docDefinition) {
      this.docDefinition = docDefinition
    },
    isSubmitDisabled() {
      return (
        !this.agreement ||
        !this.invoiceDate ||
        this.usedInvoiceNumbers.includes(this.invoiceNumber) ||
        !this.invoiceNumber ||
        this.submitLoading ||
        this.unApprovedTicketCount > 0 ||
        this.uninvoicedTickets.length > 0
      )
    },
    updateTotals(totals, revenue, deductions, afterCommission) {
      this.totals = totals
      this.revenue = revenue
      this.deductions = deductions
      this.afterCommission = afterCommission
      this.isLoading = false
    },
    async submitInvoice() {
      const lockoutPeriodActive = this.checkForLockoutPeriod()
      if (!this.isSubmitDisabled() && !lockoutPeriodActive) {
        //this ticket list length limit is enforced due to the size of the string that we allow in the SQL database,
        //this can be changed if we want to raise it in the future
        if (this.ticketList.length > 300) {
          this.showError('Too many tickets on invoice, please contact support')
          return
        }
        this.loadingValue = 0
        this.loadingMessage = 'Generating PDF'
        this.submitLoading = true
        const userData = JSON.parse(sessionStorage.userData)

        let url
        //add the pdf to storage
        try {
          let docDefinition = generateDocDefinition(
            this.revenue,
            this.deductions,
            this.afterCommission,
            this.totals,
            this.ticketList,
            this.selectedMonth,
            this.invoiceDate,
            this.invoiceNumber,
            this.selectedUnit,
            this.orgData,
            this.base64Logo,
          )
          const pdfDocGenerator = pdfMake.createPdf(docDefinition)
          const storageRef = firebase
            .storage()
            .ref(`${sessionStorage.selectedOrg}/invoices/${this.invoiceNumber}/generatedPDF`)
          const wait = () =>
            new Promise(resolve => {
              pdfDocGenerator.getBlob(async blob => {
                await storageRef.put(blob)
                url = await storageRef.getDownloadURL()
                resolve()
              })
            })
          await wait()
          this.showSuccess('PDF uploaded to storage')
          this.loadingValue = 15
          this.loadingMessage = 'Updating Database'
        } catch (error) {
          console.log(error)
          this.showError('Error uploading pdf to storage')
          this.submitLoading = false
          return false
        }

        //add the invoice to the orgs invoice collection
        try {
          await firebase
            .firestore()
            .collection('orgs')
            .doc(sessionStorage.selectedOrg)
            .collection('invoices')
            .doc(this.invoiceNumber)
            .set({
              totals: this.totals,
              invoiceDate: new Date(moment(this.invoiceDate)),
              invoiceMonth: new Date(moment(this.selectedMonth)),
              unit: this.selectedUnit,
              submittedBy: { name: userData.name, uid: userData.uid },
              submittedDate: new Date(Date.now()),
              pdfURLs: { generated: url, uploaded: null },
            })
          this.showSuccess('Invoice uploaded to database')
          this.loadingValue = 40
          this.loadingMessage = 'Submitting Invoice'
        } catch (error) {
          console.log(error)
          this.showError('Error uploading invoice to database')
          this.submitLoading = false
          return
        }

        //update the orgs invoice numbers with the new invoice number
        try {
          this.usedInvoiceNumbers.push(this.invoiceNumber)
          await firebase.firestore().collection('orgs').doc(sessionStorage.selectedOrg).update({
            invoiceNumbers: this.usedInvoiceNumbers,
          })
          this.showSuccess('Invoice submitted succesfully')
          this.loadingValue = 60
          this.loadingMessage = 'Marking Tickets as Invoiced'
        } catch (error) {
          console.log(error)
          this.showError('Invoice submission failed')
          this.submitLoading = false
          return
        }

        //update the tickets in the SQL database to show as invoiced by the contractor
        try {
          let userData = JSON.parse(sessionStorage.userData)

          axios
            .post(
              // 'https://localhost:44370/api/Tickets/update-contractor-invoiced',
              'https://wilardapi.azurewebsites.net/api/Tickets/update-contractor-invoiced',
              {
                ticketNums: this.ticketNumbers,
                invoiced: 'Yes',
                invoicedBy: userData.name,
                invoicedNumber: this.invoiceNumber,
              },
              {
                headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
                // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
              },
            )
            .then(response => {
              if (response.data === this.ticketList.length) {
                //success
                this.showSuccess('Tickets Marked as Invoiced succesfully')
                this.loadingValue = 75
                this.loadingMessage = 'Updating Notifications'
                // this.updateUnapprovedTicketCount()
              } else {
                this.showError('Error marking tickets as invoiced')
                this.submitLoading = false
                return
              }
            })
        } catch (error) {
          console.log(error)
          this.showError('Invoice submission failed')
          this.submitLoading = false
          return
        }

        //Update notifications for tickets that have been invoiced as invoiced in firebase
        try {
          for (let i = 0; i < this.ticketNumbers.length; i++) {
            let querySnapshot = await firebase
              .firestore()
              .collection('modifiedTickets')
              .where('ticketNum', '==', Number(this.ticketNumbers[i]))
              .get()
            if (!querySnapshot.empty) {
              const page = querySnapshot.docs[0]
              await page.ref.update({ invoiced: true })
            }
          }
          this.showSuccess('Notifications updated succesfully')
          this.loadingValue = 90
          this.loadingMessage = 'Emailing Admin'
        } catch (error) {
          console.log(error)
          this.showError('Notification update failed')
          this.submitLoading = false
          return
        }

        //send email with invoice info and pdf attached
        let emailSuccessful = await this.sendEmail()
        if (emailSuccessful) {
          this.loadingValue = 100
          this.submitLoading = false
          this.invoiceSubmitted = true
          this.showSuccess('Invoice Email Sent')
        } else {
          console.log(`Error sending invoice email: ${error}`)
          this.submitLoading = false
          this.showError('Error sending invoice email')
        }
      }
    },
    getCBSData: function () {
      try {
        axios
          .post(
            // 'https://localhost:44370/api/Tickets/contractor-balance-sheet',
            'https://wilardapi.azurewebsites.net/api/Tickets/contractor-balance-sheet',
            {
              unitID: this.selectedUnit.toString(),
              production: 'Yes,No,N12,D15,T0,L12',
              commision: '0.15',
              dateA: moment(this.selectedMonth).startOf('month').format('YYYY-MM-DD'),
              dateB: moment(this.selectedMonth).endOf('month').format('YYYY-MM-DD'),
              customer: '0',
              operations: ' ',
            },
            {
              headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
              // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
            },
          )
          .then(async response => {
            this.ticketListWithInvoiced = await generateTicketList(response.data)

            // add a flag to these tickets that says whether the job has been approved before and if the ticket was created before the last
            // time the job was updated
            let ticketListWithInvoicedCopy = JSON.parse(JSON.stringify(this.ticketListWithInvoiced))
            for (const ticket of ticketListWithInvoicedCopy) {
              if (!ticket.contractorInvoiced) {
                await axios
                  .post(
                    // 'https://localhost:44370/api/Tickets/search-tickets',
                    'https://wilardapi.azurewebsites.net/api/Tickets/search-tickets',
                    {
                      ticketNum: ticket.ticketNum,
                      ticketDateA: null,
                      ticketDateB: null,
                      jobID: '',
                      chargeToCu: '',
                      recievedToCu: '',
                      deliverToCu: '',
                      deliveredToLSD: '',
                      chargedToLSD: '',
                      recievedFromLSD: '',
                      unitID: '',
                      subtotal: '',
                      gst: '',
                      total: '',
                      preJobNotes: '',
                      jobDesc: '',
                      approved: '',
                    },
                    {
                      headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
                      // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
                    },
                  )
                  .then(async response => {
                    const ticketData = response.data[0]

                    if (ticketData.jobApprovedDate) {
                      ticket['jobHasBeenCompletedBefore'] = true
                      let lastEditTime = null
                      const snapshot = await firebase
                        .firestore()
                        .collection('modifiedJobsWilard')
                        .where('jobID', '==', ticket.jobID)
                        .get()

                      snapshot.docs.forEach(doc => {
                        lastEditTime = doc.data().lastUpdated
                      })

                      if (lastEditTime) {
                        if (new Date(ticketData.timeCreated) < lastEditTime.toDate()) {
                          ticket['ticketCreatedBeforeLastJobEdit'] = true
                        } else {
                          ticket['ticketCreatedBeforeLastJobEdit'] = false
                        }
                      } else {
                        ticket['ticketCreatedBeforeLastJobEdit'] = false
                      }
                    } else {
                      ticket['jobHasBeenCompletedBefore'] = false
                    }
                  })
                  .catch(err => {
                    console.log(`Error calling search tickets request: ${err}`)
                    this.$toasted.show('Error getting ticket data', {
                      duration: null,
                      keepOnHover: true,
                      type: 'error',
                    })
                  })
              }
            }
            this.ticketListWithInvoiced = ticketListWithInvoicedCopy
          })
          .catch(err => {
            console.log(`Error calling get customers network request: ${err}`)
            this.showError('Error getting customer list')
          })
      } catch (err) {
        console.log(`Error getting CBS data: ${err}`)
        this.showError('Error getting customer data')
      }
    },
    checkInvoiceNumber() {
      return this.usedInvoiceNumbers.includes(this.invoiceNumber)
    },
    async getUninvoicedTickets() {
      try {
        const response = await axios.post(
          'https://wilardapi.azurewebsites.net/api/Tickets/contractor-balance-sheet',
          // 'https://localhost:44370/api/Tickets/contractor-balance-sheet',
          {
            unitID: this.selectedUnit.toString(),
            production: 'Yes,No,N12,D15,T0,L12',
            commision: '0.15',
            dateA: '2024-03-01',
            dateB: moment(this.selectedMonth).startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
            customer: '0',
            operations: '',
          },
          {
            headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
            // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
          },
        )
        const ticketList = response.data
        this.uninvoicedTickets = await generateTicketList(ticketList)
        // filter out tickets that have already been invoiced
        this.uninvoicedTickets = this.uninvoicedTickets.filter(ticket => ticket.contractorInvoiced === false)
      } catch (err) {
        console.log(`Error getting CBS data: ${err}`)
        this.$toasted.show('Error getting CBS data', {
          duration: null,
          keepOnHover: true,
          type: 'error',
        })
      }
    },
    getOrgData: function () {
      try {
        firebase
          .firestore()
          .collection('orgs')
          .doc(sessionStorage.selectedOrg)
          .get()
          .then(doc => {
            if (doc.exists) {
              this.orgData = doc.data()
              this.units = doc.data().units ? doc.data().units : []
              this.units.sort()
              this.usedInvoiceNumbers = doc.data().invoiceNumbers ? doc.data().invoiceNumbers : []
              //if they have uploaded a logo, get the url.
              if (doc.data().logoURL != undefined) {
                this.getBase64ImageFromURL(doc.data().logoURL).then(resp => {
                  this.base64Logo = resp
                })
              }
            }
          })
          .catch(err => {
            console.log(`Error executing get org data function: ${err}`)
            this.showError('Error sending organization info')
          })
      } catch (err) {
        console.log(`Error getting org data: ${err}`)
        this.showError('Error sending org data')
      }
    },
    checkForLockoutPeriod() {
      let lockoutPeriodActive = false
      const today = moment(Date.now()).format('YYYY-MM-DD')
      const first = moment().startOf('month').format('YYYY-MM-DD')
      const second = moment(first).add(1, 'days').format('YYYY-MM-DD')

      if ((today === first || today === second) && !this.overrideLockoutPeriod) {
        const third = moment(second).add(1, 'days').format('MMMM Do')
        lockoutPeriodActive = true
        this.lockoutPeriod = true
        this.lockoutMessage = `Invoice Submission is currently locked. Contractors can not submit invoices on the 1st or 2nd of the month. Please come back on ${third}.`
        alert(this.lockoutMessage)
      } else {
        lockoutPeriodActive = false
        this.lockoutPeriod = false
      }

      return lockoutPeriodActive
    },
    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image()
        // img.setAttribute('crossOrigin', 'anonymous')
        img.crossOrigin = 'anonymous'
        img.onload = () => {
          var canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height
          var ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          var dataURL = canvas.toDataURL('image/png')
          resolve(dataURL)
        }
        img.onerror = error => {
          reject(error)
        }
        img.src = url
      })
    },
    showSuccess: function (message) {
      this.$toasted.show(message, {
        duration: '9000',
        keepOnHover: true,
        type: 'success',
      })
    },
    showError: function (message) {
      this.$toasted.show(message, {
        duration: null,
        keepOnHover: true,
        type: 'error',
      })
    },
    updateApprovedTicketCount: function (data) {
      //this is emitted from the CBSDATA component and will provide us with a count of unapproved tickets.
      this.unApprovedTicketCount = data.unapprovedTicketCount
    },
    handleVisibilityChange: function () {
      if (!document.hidden) {
        // user returned to the page, check for the lockout period again
        this.checkForLockoutPeriod()
      }
    },
  },
  watch: {
    selectedMonth() {
      if (this.selectedMonth && this.selectedUnit) {
        this.agreement = false
        this.invoiceNumber = null
        this.invoiceDate = null
        this.invoiceSubmitted = false
        this.isLoading = true
        this.getCBSData()
        this.getUninvoicedTickets()
        this.checkForLockoutPeriod()
      }
    },
    selectedUnit() {
      if (this.selectedMonth && this.selectedUnit) {
        this.agreement = false
        this.invoiceNumber = null
        this.invoiceDate = null
        this.invoiceSubmitted = false
        this.isLoading = true
        this.getCBSData()
        this.getUninvoicedTickets()
        this.checkForLockoutPeriod()
      }
    },
    overrideLockoutPeriod(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkForLockoutPeriod()
      }
    },
    // ticketList() {
    //   this.ticketsOnUnapprovedJobsCount = this.ticketList.filter(o => o.jobApproved === false).length
    // },
  },
  computed: {
    ticketList() {
      //ticketListWithInvoiced includes invoiced tickets, ticketList does not
      return this.ticketListWithInvoiced === null
        ? null
        : this.ticketListWithInvoiced.filter(x => x.contractorInvoiced !== true)
    },
    ticketNumbers() {
      //returns an array of only the ticket numbers of the tickets that are on the invoice
      if (this.ticketList !== null) {
        let tempArr = []
        for (let i = 0; i < this.ticketList.length; i++) {
          tempArr.push(this.ticketList[i].ticketNum.toString())
        }
        return tempArr
      }
    },
    overrideLockoutPeriod() {
      return this.$store.state.userData?.settings?.overrideLockoutPeriod || false
    },
  },
  mounted() {
    this.getOrgData()
    this.checkForLockoutPeriod()
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
}
</script>

<style></style>
